
import { Component, Prop, Vue } from 'vue-property-decorator';

export class ArtifactItem {
    title: string
    imageName: string | undefined
    createDate: string
    type: string
    technologyList: Array<string>
    appLinks: Array<string> | undefined
    githubLinks: Array<string> | undefined
    summaryList: Array<string>

    constructor( title: string, imageName: string | undefined, createDate: string, type: string, technologyList: Array<string>,
                appLinks: Array<string> | undefined, githubLinks: Array<string> | undefined, summaryList: Array<string> ) {
        this.title = title
        this.imageName = imageName
        this.createDate = createDate
        this.type = type
        this.technologyList = technologyList
        this.appLinks = appLinks
        this.githubLinks = githubLinks
        this.summaryList = summaryList
    }
}

@Component
export default class ArtifactListItem extends Vue {
    @Prop()
    private title!: string
    @Prop( { default: undefined } )
    private imageName!: string
    @Prop()
    private createDate!: string
    @Prop()
    private type!: string
    @Prop()
    private technologyList!: Array<string>
    @Prop( { default: undefined } )
    private appLinks!: Array<string>
    @Prop( { default: undefined } )
    private githubLinks!: Array<string>
    @Prop()
    private summaryList!: Array<string>

    get technologies() {
        return this.technologyList.join( "、" );
    }
}
